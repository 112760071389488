$backgroundColor: #6699cc;
$contentBackgroundColor:  #d4e8f9;
$bodyColor: #000000;
$bodyFont: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

body {
    background-color: $backgroundColor !important;  
    color: $bodyColor;
    font-family: $bodyFont;

    background-image: url(/assets/images/background.jpg);
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

header, header nav.navbar{
  min-height: 90px;
}

.navbar{
  a.active, a.sub-active{
    color: $backgroundColor !important;
  }
}

content, footer{
    background-color: rgba(212, 232, 249, 0.85) !important;
}

.partner_block {
  .text_element {
    width: 300px;
  }

  .logo {
    img {
      max-width: 200px;
    }
  }
}

.team_image_block {
  float: left;
  margin-right: 1.5em;
}

.team_text_block{
  table{
    td{
      padding: 0.5em;
      vertical-align: top;
    }
  }
}

footer {

  span {
    font-size: 10px;
    margin-right: 1em;
  }
}

table.padded-table{
  td{
    padding:0.5em;
  }
}
